<template>
  <span>
    <v-row justify="space-around">
      <v-col cols="12" sm="6" md="5">
        <v-select
          :items="itemsSelectCategoriaServicos"
          v-model="categoriaServicosSelecionada"
          label="Categoria de Serviços"
          hide-details
        />
      </v-col>

      <v-col cols="12" sm="6" md="5">
        <v-select
          :items="itemsSelectServico"
          v-model="servicoSelecionado"
          label="Serviço"
          hide-details
        />
      </v-col>
    </v-row>
    <charts-geral-servicos
      ref="chartsGeralServicos"
      :competencia_de="competencia_de"
      :competencia_ate="competencia_ate"
      :tipo_servico="servicoSelecionado"
      @updateLoadingDados="$emit('updateLoadingDados', $event)"
      @setComponentIsMounted="componentsIsMounted[$event] = true"
    />

    <v-row dense justify="center">
      <v-col cols="auto">
        <v-switch
          class="switch-visao-graficos"
          color="primary"
          dense
          hide-details
          false-value="anual"
          true-value="mensal"
          :disabled="loadingDados"
          v-model="visaoGraficos"
        >
          <template #prepend>
            <label
              class="v-label"
              :class="
                visaoGraficos === 'anual'
                  ? 'text--primary'
                  : 'text--secondary font-weight-light'
              "
            >
              Visão Anual
            </label>
          </template>
          <template #append>
            <label
              class="v-label"
              :class="
                visaoGraficos === 'mensal'
                  ? 'text--primary'
                  : 'text--secondary font-weight-light'
              "
            >
              Visão Mensal
            </label>
          </template>
        </v-switch>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title style="display:block;">
        <h4 v-if="servicoSelecionado === -1">
          Todos os Serviços
        </h4>
        <h4 v-else>
          Serviço {{ servicoSelecionado }} - {{ nomeServicoSelecionado }}
        </h4>
        <div v-if="servicoSelecionado !== -1" class="body-1">
          Prazo Regulado: {{ rawPrazoReguladoServico || "-" }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-auto" justify="center">
          <v-col cols="12">
            <charts-realizados-servicos
              ref="chartsRealizadosServicos"
              :competencia_de="competencia_de"
              :competencia_ate="competencia_ate"
              :competencias="competencias"
              :anos="anos"
              :meses="meses"
              :servico="servicoSelecionado"
              :prazoReguladoServico="prazoReguladoServico"
              :visaoGraficos="visaoGraficos"
              @updateLoadingDados="$emit('updateLoadingDados', $event)"
              @setComponentIsMounted="componentsIsMounted[$event] = true"
            />
          </v-col>
          <v-col cols="12">
            <charts-compensacoes-servicos
              ref="chartsCompensacoesServicos"
              :competencia_de="competencia_de"
              :competencia_ate="competencia_ate"
              :competencias="competencias"
              :anos="anos"
              :meses="meses"
              :servico="servicoSelecionado"
              :prazoReguladoServico="prazoReguladoServico"
              :visaoGraficos="visaoGraficos"
              @updateLoadingDados="$emit('updateLoadingDados', $event)"
              @setComponentIsMounted="componentsIsMounted[$event] = true"
            />
          </v-col>
          <v-col cols="12">
            <charts-pendentes-servicos
              ref="chartsPendentesServicos"
              :competencia_de="competencia_de"
              :competencia_ate="competencia_ate"
              :competencias="competencias"
              :anos="anos"
              :meses="meses"
              :servico="servicoSelecionado"
              :visaoGraficos="visaoGraficos"
              @updateLoadingDados="$emit('updateLoadingDados', $event)"
              @setComponentIsMounted="componentsIsMounted[$event] = true"
            />
          </v-col>
          <v-col cols="12">
            <charts-entrantes-servicos
              ref="chartsEntrantesServicos"
              :competencia_de="competencia_de"
              :competencia_ate="competencia_ate"
              :competencias="competencias"
              :anos="anos"
              :meses="meses"
              :servico="servicoSelecionado"
              :visaoGraficos="visaoGraficos"
              @updateLoadingDados="$emit('updateLoadingDados', $event)"
              @setComponentIsMounted="componentsIsMounted[$event] = true"
            />
          </v-col>
          <v-col cols="12">
            <charts-suspensos-servicos
              ref="chartsSuspensosServicos"
              :competencia_de="competencia_de"
              :competencia_ate="competencia_ate"
              :competencias="competencias"
              :anos="anos"
              :meses="meses"
              :servico="servicoSelecionado"
              :visaoGraficos="visaoGraficos"
              @updateLoadingDados="$emit('updateLoadingDados', $event)"
              @setComponentIsMounted="componentsIsMounted[$event] = true"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
  import IndicadoresServicosV2Service from "@/services/IndicadoresServicosV2Service.js";

  export default {
    name: "ServicosV2Dashboard",
    components: {
      ChartsGeralServicos: () =>
        import("@/components/validacoes/Charts/ChartsGeralServicos.vue"),
      ChartsRealizadosServicos: () =>
        import("@/components/validacoes/Charts/ChartsRealizadosServicos.vue"),
      ChartsPendentesServicos: () =>
        import("@/components/validacoes/Charts/ChartsPendentesServicos.vue"),
      ChartsCompensacoesServicos: () =>
        import("@/components/validacoes/Charts/ChartsCompensacoesServicos.vue"),
      ChartsEntrantesServicos: () =>
        import("@/components/validacoes/Charts/ChartsEntrantesServicos.vue"),
      ChartsSuspensosServicos: () =>
        import("@/components/validacoes/Charts/ChartsSuspensosServicos.vue"),
    },
    props: {
      competencias: {
        type: Array,
        required: true,
      },
      competencia_de: {
        type: String,
        required: true,
      },
      competencia_ate: {
        type: String,
        required: true,
      },
      anos: {
        type: Array,
        required: true,
      },
      meses: {
        type: Array,
        required: true,
      },
      loadingDados: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data: () => ({
      categoriaServicosSelecionada: 1,
      servicoSelecionado: 1,
      visaoGraficos: "mensal",
      categoriasServicos: [],
      prazoReguladoServico: null,
      rawPrazoReguladoServico: null,
      componentsIsMounted: {
        chartsGeralServicos: false,
        chartsRealizadosServicos: false,
        chartsPendentesServicos: false,
        chartsEntrantesServicos: false,
        chartsSuspensosServicos: false,
        chartsCompensacoesServicos: false,
      },
    }),
    computed: {
      itemsSelectCategoriaServicos() {
        return this.categoriasServicos.map((categoria) => {
          return { text: categoria.nome, value: categoria.id };
        });
      },
      itemsSelectServico() {
        if (this.categoriaServicosSelecionada == -1) {
          this.servicoSelecionado = -1;
          return [
            {
              text: "TODOS",
              value: -1,
            },
          ];
        }
        const index = this.categoriasServicos.findIndex(
          (categoria) => categoria.id === this.categoriaServicosSelecionada
        );

        return this.categoriasServicos[index]?.tipos_servicos.map(
          (tipo_servico) => {
            return {
              text: `Serviço ${tipo_servico.codigo} - ${tipo_servico.detalhe_servico.artigo_ren_1000} - ${tipo_servico.detalhe_servico.nome_servico}`,
              value: tipo_servico.codigo,
            };
          }
        );
      },
      nomeServicoSelecionado() {
        if (this.categoriaServicosSelecionada == -1) {
          return "TODOS";
        }
        const indexCategoria = this.categoriasServicos.findIndex(
          (categoria) => categoria.id === this.categoriaServicosSelecionada
        );
        const indexServico = this.categoriasServicos[
          indexCategoria
        ]?.tipos_servicos.findIndex(
          (servico) => servico.codigo === this.servicoSelecionado
        );
        const servico = this.categoriasServicos[indexCategoria]?.tipos_servicos[
          indexServico
        ];

        return (
          servico?.detalhe_servico.artigo_ren_1000 +
          " - " +
          servico?.detalhe_servico.nome_servico
        );
      },
    },
    created() {
      this.$emit("updateLoadingDados", true);
      this.getCategoriasServicos();
    },
    methods: {
      async getDados(
        competencia_de = this.competencia_de,
        competencia_ate = this.competencia_ate,
        competencias = this.competencias,
        anos = this.anos
      ) {
        await this.getPrazoReguladoServico();

        this.$refs.chartsGeralServicos.getDados(
          competencia_de,
          competencia_ate
        );
        this.$refs.chartsRealizadosServicos.getDados(
          competencia_de,
          competencia_ate,
          this.servicoSelecionado,
          this.prazoReguladoServico,
          competencias,
          anos
        );
        this.$refs.chartsPendentesServicos.getDados(
          competencia_de,
          competencia_ate,
          this.servicoSelecionado,
          this.prazoReguladoServico,
          competencias,
          anos
        );

        this.$refs.chartsCompensacoesServicos.getDados(
          competencia_de,
          competencia_ate,
          this.servicoSelecionado,
          competencias,
          anos
        );
        this.$refs.chartsEntrantesServicos.getDados(
          competencia_de,
          competencia_ate,
          this.servicoSelecionado,
          competencias,
          anos
        );
        this.$refs.chartsSuspensosServicos.getDados(
          competencia_de,
          competencia_ate,
          this.servicoSelecionado,
          competencias,
          anos
        );
      },
      getPrazoReguladoServico() {
        return IndicadoresServicosV2Service.getPrazoReguladoServico(
          this.servicoSelecionado
        )
          .then((res) => {
            this.prazoReguladoServico = Number(res.prazo_regulado);
            this.rawPrazoReguladoServico = res.prazo_regulado_raw;
          })
          .catch((err) => {
            this.$toast.error(
              "Erro ao recuperar Prazo Regulado do serviço selecionado.",
              "",
              {
                position: "topRight",
              }
            );
            throw err;
          });
      },
      getCategoriasServicos() {
        return IndicadoresServicosV2Service.getCategoriasServicos()
          .then((res) => {
            this.categoriasServicos = res;
            this.categoriasServicos.unshift({
              nome: "TODOS",
              id: -1,
            });
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar categorias de serviços.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
    },
    watch: {
      componentsIsMounted: {
        handler() {
          if (
            Object.values(this.componentsIsMounted).every((el) => el === true)
          ) {
            this.getDados();
          }
        },
        deep: true,
      },
      categoriaServicosSelecionada() {
        if (this.categoriaServicosSelecionada == -1) {
          return -1;
        }
        const index = this.categoriasServicos.findIndex(
          (categoria) => categoria.id === this.categoriaServicosSelecionada
        );

        this.servicoSelecionado = this.categoriasServicos[
          index
        ].tipos_servicos[0].codigo;
      },
      servicoSelecionado() {
        this.getDados();
      },
    },
  };
</script>

<style></style>
